import { isDev } from "./Utils"

const _programNames = [
  "3-9-12 k Одинокая женщина",
  "4-12-16 k Император",
  "5-6 Красота. Уют в Доме",
  "5-6-17 Красота. Физическая или духовная",
  "5-10-15 Духовная Миссия Объединения",
  "5-10-22 Инквизиция",
  "5-14-9 Месть",
  "5-14-19 Программа Миллионера",
  "5-15-20 k Бунтарь",
  "5-17-12 Публичный Позор",
  "6-15-9 Сказочный Мир. Писатель. Режисер",
  "6-15-21 Программа Разгула",
  "6-18-6 k Любовная магия",
  "7-12-19 k Воин",
  "7-21-10 k Воин веры",
  "7-21-14 Пытки, Издевательства",
  "8-6-14 k Диктатор, влиятельный человек",
  "8-15-5 k Предательство. Страсти в семье",
  "9-8-17 Сокрытие Истины",
  "9-9-18 k Волшебник, Отшельничество, Непринятие Знаний",
  "9-11-20 Исчезновение Рода. Вырождение",
  "9-16-7 Молчание",
  "9-18-9 k Волшебник, Отшельничество, Непринятие Знаний",
  "9-19-10 Чистый Поток",
  "10-3-13 k Суицид",
  "10-8-16 Сговор. Заговорщики",
  "10-21-4 k Угнетенная душа",
  "11-6-17 k Загубленный талант",
  "11-6-22 Манипуляция Ребёнком",
  "11-9-16 Саморазрушение",
  "11-15-8 k Физическая агрессия",
  "11-19-10 Выгорание Вспышка",
  "12-18-3 k Физические страдания",
  "13-6-19 k Избавление От Старого",
  "13-7-20 Опасность В Дороге",
  "13-18-5 Сокральная жертва",
  "13-21-7 k Разрушение. Смерть многим душам",
  "13-21-8 k Системная Ошибка. Вирус",
  "14-6-20 k Душа, которую принесли в жертву",
  "14-22-8 k Скупость",
  "15-18-6 k Темный маг",
  "15-22-7 k Азарт, Риск, Адреналин",
  "16-21-10 k Духовный жрец",
  "17-6-5 k Гордыня",
  "17-20-3 Однополая Любовь",
  "17-22-5 Академические Знания",
  "18-5-5 Магические знания. Идеализация семьи",
  "18-6-12 Комфорт или Суета",
  "18-7-7 Страх Карьеры, Инициативы, Дороги",
  "18-8-8 Страх разочарования",
  "18-9-9 Волшебник. Магические знания",
  "18-10-10 Страх доверия Богу, позитивному потоку",
  "18-11-11 Страх Принятия Магической Силы",
  "19-3-22 k Нерожденное дитя",
  "19-7 Успех в Деньгах",
  "20-3-10 Обман Со Стороны Женщин. Страх Материнства",
  "20-4-11 Укрощение Агрессии. Дрессировщик",
  "20-6-8 k Разочарование рода",
  "21-9-3 k Надзиратель",
  "22-3-7 k Узник. Несвободная душа",
  "22-4-8 Тюремная Программа",
  "22-4-9 Тюремная Программа",
  "22-7 Идеализация Семьи",
  "22-22-8 Тюремная Программа",
  "19-11-3 Бесплодие",
]

export const programNames = _programNames.map((d, indx) => {
  let words = d.split(" ")
  let isKarmicTail = words[1].length === 1 ? true : undefined
  let codes = words[0].split("-").map(c => +c)
  let name = words.length === 1 ? "no name" : d.substring(words[0].length + (isKarmicTail ? 3 : 1))
  let fullName = words[0] + " " + name
  //if (isDev()) console.log(indx, codes, fullName, isKarmicTail)
  return { PId: words[0], codes, name, fullName, isKarmicTail }
})