import React from 'react'
import { useAuth } from './useAuth'
import { LoginComponent } from './LoginComponent'
import { useLocaleContext } from './Locale'
import { Calculator } from './calc/Calculator'

export const Content = () => {
  const { isLogin, isBlocked, isSuperAdmin } = useAuth()
  const { ls } = useLocaleContext()

  return (
    <div>
      {isLogin && !isBlocked && <Calculator />}
      {!isLogin && <LoginComponent />}
      {isLogin && isBlocked && <p>
        {ls(
          "Your account is blocked. Please contact your administrator.",
          "Ihr Konto ist gesperrt. Bitte kontaktieren Sie Ihren Administrator.",
          "Ваш аккаунт заблокирован. Пожалуйста, свяжитесь с администратором.")}
      </p>}
    </div>
  )
} 