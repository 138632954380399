import React, { useState, useCallback, useEffect } from "react"
import { useLocaleContext } from "../Locale"
import { cn } from '../classnames'
import { useDate } from "./useDate"

export const InputWithError = ({ first }) => {
  const { ls } = useLocaleContext()
  const { isValidDate, date1, date2, setDate1, setDate2 } = useDate()
  const [text, setText] = useState(first ? date1 : date2)
  const [alarm, setAlarm] = useState("")

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      _checkDate(text)
    }
  }

  const _checkDate = useCallback(
    (inputText) => {
      if (isValidDate(inputText)) {
        setAlarm("")
        first ? setDate1(inputText) : setDate2(inputText)
      } else {
        setAlarm(
          ls(
            "Invalid date. Expected format dd.mm.yyyy",
            "Ungültiges Datum. Erwartetes Format TT.MM.JJJJ",
            "Неверная дата. Ожидаемый формат дд.мм.гггг"
          )
        )
      }
    },
    [isValidDate, first, setDate1, setDate2, ls]
  )

  useEffect(() => {
    // Обновление `text` при изменении `date1` или `date2` извне, только если оно отличается от `text`
    const newDate = first ? date1 : date2
    if (newDate !== text) {
      setText(newDate)
    }
  }, [date1, date2, first])

  return (
    <>
      <input style={{width:100}}
        type="text"
        className={cn({
          "i-error": alarm,
          "menu-inside-item": true,
          "coolInput": true,
        })}
        placeholder={ls("dd.mm.yyyy", "TT.MM.JJJJ", "дд.мм.гггг")}
        onChange={(e) => setText(e.target.value)}
        value={text}
        onKeyDown={onKeyDown}
      />
      <div
        className={cn({
          "validation-message": true,
          "i-error": alarm,
        })}
      >
        {alarm}
      </div>
    </>
  )
}
