import React, { useCallback, useState, useEffect, useRef } from "react"
import { dateParse, solve, checkTriplet, isDev, cartesian2Polar, polar2Cartesian, solve2 } from './Utils'
import { saveImageFirst, saveImageSecond, saveImageThird } from "./Storage"
import { collectNames, UndefinedComponemt } from "./Components"
import { programNames } from "./programsList"
import { useDate } from "./useDate"
import { useAuth } from "../useAuth"

let numN = 0

function newNode(id, value, grad, diam = 500, color = undefined, textColor = "black", notShow = undefined, size = 1) {
  diam = canvas_width / 500 * diam
  return { id, value, a: grad, d: diam, color, textColor, notShow, num: numN++, size: size }
}

const sqrt2 = Math.sqrt(2)
const sqrt2_2 = Math.sqrt(2 + sqrt2)
const kHalfShoulder = 1 / (2 / sqrt2_2 + 1)
const dDiag = 500 * kHalfShoulder / 4 / 1.65
const dHV = dDiag * 1.435

const mHV = [[-90, 0, -1], [-45, 1, 0], [0, 1, 0], [45, 0, 1], [90, 0, 1], [135, -1, 0], [180, -1, 0], [225, 0, -1]]
const mD = [[-90, 1, -1], [-45, 1, -1], [0, 1, 1], [45, 1, 1], [90, -1, 1], [135, -1, 1], [180, -1, -1], [225, -1, - 1]]

function changeXY(matrix, grad, x, y, dxy) {
  let m = matrix.find(i => i[0] === grad)
  if (!m) throw new Error('not found')

  return [x + m[1] * dxy, y + m[2] * dxy]
}

function changeXY_HV(grad, x, y) { return changeXY(mHV, grad, x, y, dHV) }
function changeXY_D(grad, x, y) { return changeXY(mD, grad, x, y, dDiag) }

function _newYearNodes(base, data, startGrad, startDiam, color, firstFunc, secondFunc, notShow) {
  let result = []
  let [x, y] = polar2Cartesian(startDiam, startGrad)

  for (let i = 0; i < data.length; i++) {
    [x, y] = i < 4 ? firstFunc(startGrad, x, y) : secondFunc(startGrad, x, y)
    let [diam, grad] = cartesian2Polar(x, y)
    result.push(newNode(i + 1 + base, data[i], grad, diam, color, "black", notShow, 1))
  }

  return result
}

function newYearsNodes(base, data, startGrad, startDiam, color, notShow) {
  if (startGrad % 90 === 0) return _newYearNodes(base, data, startGrad, startDiam, color, changeXY_D, changeXY_HV, notShow)
  return _newYearNodes(base, data, startGrad, startDiam, color, changeXY_HV, changeXY_D, notShow)
}

function i64(id) { return id + 64 }
function i256(id) { return id + 256 }

function createNodes({ a, b, c, d, e, a1, b1, c1, d1, a2, a3, a4, b2, b3, b4, c2, c3, d2, d3, c4, c41, c42, a12, b12, c12, d12, a13, b13, c13, d13, a04, b04,
  years1, years2, years3, years4, years5, years6, years7, years8 }, notShow) {

  let baseDiam = 500

  let nodes = []

  nodes.push(newNode(0, a, -90, baseDiam, "#993399", "white", undefined, 3)) // "violet")
  nodes.push(newNode(16, b, 0, baseDiam, "#993399", "white", undefined, 3)) // "violet")
  nodes.push(newNode(32, c, 90, baseDiam, "#d24944", "white", undefined, 3)) //"red")
  nodes.push(newNode(48, d, 180, baseDiam, "#d24944", "white", undefined, 3)) //"red")

  nodes.push(newNode(-1, e, 0, 0, "#f7de74", undefined, undefined, 3)) // "yellow")

  nodes.push(newNode(8, a1, -45, baseDiam, "white", "black", undefined, 3))
  nodes.push(newNode(24, b1, 45, baseDiam, "white", undefined, undefined, 3))
  nodes.push(newNode(40, c1, 90 + 45, baseDiam, "white", undefined, undefined, 3))
  nodes.push(newNode(56, d1, 180 + 45, baseDiam, "white", undefined, undefined, 3))

  nodes.push(newNode(i64(2), a2, -90, 350, "#3db4f0", "white")) // light blue
  nodes.push(newNode(i64(1), a3, -90, 415, "#3366cc", "white", undefined, 2)) // blue
  nodes.push(newNode(-1, a4, -90, 275, undefined, 0, true))

  nodes.push(newNode(i64(16 + 2), b2, 0, 350, "#3db4f0", "white")) // light blue
  nodes.push(newNode(i64(16 + 1), b3, 0, 415, "#3366cc", "white", undefined, 2)) // blue
  nodes.push(newNode(-1, b4, 0, 275, undefined, 0, true))

  nodes.push(newNode(i64(32 + 2), c2, 90, 350, "#d88b4c", "white"))// "orange")
  nodes.push(newNode(i64(32 + 1), c3, 90, 415, undefined, undefined, undefined, 2))

  nodes.push(newNode(i64(48 + 2), d2, 180, 350, "#d88b4c", "white"))// "orange")
  nodes.push(newNode(i64(48 + 1), d3, 180, 415, undefined, undefined, undefined, 2))

  nodes.push(newNode(i256(0), c4, 90 + 45, 250, "white"))
  nodes.push(newNode(i256(1), c41, 90 + 30, 320, "white"))
  nodes.push(newNode(i256(2), c42, 180 - 30, 320, "white"))

  nodes.push(newNode(i64(8 + 2), a12, -45, 350, undefined, 0, notShow))
  nodes.push(newNode(i64(24 + 2), b12, 45, 350, undefined, 0, notShow))
  nodes.push(newNode(i64(40 + 2), c12, 90 + 45, 350, undefined, 0, notShow))
  nodes.push(newNode(i64(56 + 2), d12, 180 + 45, 350, undefined, 0, notShow))

  nodes.push(newNode(i64(8 + 1), a13, -45, 415, undefined, 0, notShow, 2))
  nodes.push(newNode(i64(24 + 1), b13, 45, 415, undefined, 0, notShow, 2))
  nodes.push(newNode(i64(40 + 1), c13, 90 + 45, 415, undefined, 0, notShow, 2))
  nodes.push(newNode(i64(56 + 1), d13, 180 + 45, 415, undefined, 0, notShow, 2))

  nodes.push(newNode(-1, a04, -90, 180, "#73b55f", "white")) // "green")
  nodes.push(newNode(-1, b04, 0, 180, "#73b55f", "white")) // "green")

  let color = "white"//"#f2f2f2" // "lightgray"

  let ny1 = newYearsNodes(0, years1, -90, baseDiam, color, notShow)
  let ny2 = newYearsNodes(8, years2, -45, baseDiam, color, notShow)
  let ny3 = newYearsNodes(16, years3, 0, baseDiam, color, notShow)
  let ny4 = newYearsNodes(24, years4, 45, baseDiam, color, notShow)
  let ny5 = newYearsNodes(32, years5, 90, baseDiam, color, notShow)
  let ny6 = newYearsNodes(40, years6, 90 + 45, baseDiam, color, notShow)
  let ny7 = newYearsNodes(48, years7, 180, baseDiam, color, notShow)
  let ny8 = newYearsNodes(56, years8, 180 + 45, baseDiam, color, notShow)

  return [
    ...ny1, ...ny2, ...ny3, ...ny4, ...ny5, ...ny6, ...ny7, ...ny8,
    ...nodes,
  ]
}

let canvas_dx = 290
let canvas_dy = 290
let canvas_width = 580

//const dx = 280
//const dy = 280
const scale = 1

function getPos(grad, diam = 500, s = scale) {
  return [diam * s * Math.sin(grad * Math.PI / 180) / 2 + canvas_dx, -diam * s * Math.cos(grad * Math.PI / 180) / 2 + canvas_dy]
}

export const MatrixBox = ({ first, second, third }) => {
  let [highlightedNodes, setHighlightedNodes] = useState([])
  let [canvasDom, setCanvasDom] = useState()
  let [selectedNode, setSelectedNode] = useState()
  let [mouseOver, setMouseOver] = useState()
  let { date1, date2, name1, name2 } = useDate()
  let { isFullUser } = useAuth()
  let ref = useRef()

  if (!first && !second && !third) return <UndefinedComponemt name="MatrixBox" third />

  let { d: dd, m: mm, y: yy } = dateParse(second ? date2 : date1)
  let { d: dd2, m: mm2, y: yy2 } = third ? dateParse(date2) : {}

  if (!ref.current) ref.current = { saveImage: third ? saveImageThird : first ? saveImageFirst : saveImageSecond }

  const handlerMouseHover = useCallback((e) => {
    setMouseOver(true)
  }, [])

  function handlerMouseOut(e) {
    setMouseOver(false)
  }

  function getMousePosition(canvas, event) {
    const rect = canvas.getBoundingClientRect() // Get the position of the canvas relative to the viewport
    const scaleX = canvas.width / rect.width    // Calculate the horizontal scaling factor
    const scaleY = canvas.height / rect.height  // Calculate the vertical scaling factor

    // Convert mouse coordinates from the event to canvas coordinates, taking scaling into account
    const x = (event.clientX - rect.left) * scaleX
    const y = (event.clientY - rect.top) * scaleY

    // Get the absolute mouse position in the document
    const left = event.clientX + window.scrollX
    const top = event.clientY + window.scrollY

    return { x, y, left, top }
  }

  const handlerMouseMove = useCallback((e) => {
    if (!mouseOver || !canvasDom) return
    checkPosition(getMousePosition(canvasDom, e), highlightedNodes, setSelectedNode)
  }, [highlightedNodes, mouseOver])

  const handlerClick = useCallback(e => {
    if (!canvasDom) return
    checkPosition(getMousePosition(canvasDom, e), highlightedNodes, setSelectedNode)
  }, [highlightedNodes])

  let linkNodes = (ctx, nodes, node1, node2) => {
    ctx.beginPath()
    let [x, y] = getPos(node1.a, node1.d)
    ctx.moveTo(x, y)
    let [x1, y1] = getPos(node2.a, node2.d)
    ctx.lineTo(x1, y1)
    ctx.lineWidth = 4
    ctx.strokeStyle = "lightgray"
    ctx.stroke()
  }

  let linkLineIdx = useCallback((ctx, nodes, idx1, idx2) => {
    linkNodes(ctx, nodes, nodes[idx1], nodes[idx2])
  }, [])

  let linkLineId = useCallback((ctx, nodes, id1, id2) => {
    let node1 = nodes.find(i => i.id === id1)
    let node2 = nodes.find(i => i.id === id2)
    linkNodes(ctx, nodes, node1, node2)
  }, [])

  let drawSymbols = (ctx) => {
    ctx.font = "40px Verdana"
    ctx.lineWidth = 3

    ctx.strokeStyle = "#3db4f0"
    ctx.strokeText('\u2642', 220, 225) // Mars

    ctx.strokeStyle = "#ff80ab"
    ctx.strokeText('\u2640', 360, 240) // Venus

    ctx.font = "30px Verdana"
    ctx.strokeStyle = "#ff0000"
    ctx.strokeText('\u2764', 325, 390) // Heart

    ctx.strokeStyle = "#1c5e20"
    ctx.strokeText('\uFF04', 380, 335)  // Dollar
  }

  let refCanvas = useCallback(canvas => {
    if (!canvas) return

    let hm_width = 580

    canvas.width = hm_width
    canvas.height = hm_width

    canvas_width = 500

    canvas_dx = hm_width / 2
    canvas_dy = hm_width / 2

    let nodes = createNodes(third ? solve2(solve(dd, mm, yy), solve(dd2, mm2, yy2)) : solve(dd, mm, yy), third)

    if (isFullUser) {
      if (first || second) nodes = checkKeysNodes(nodes)
      else nodes = checkKeysNodesCompatibility(nodes)
    }

    let ctx = canvas.getContext("2d")
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    ctx.textAlign = "center"
    ctx.baseLine = "middle"

    linkLineIdx(ctx, nodes, 8 + 56, 5 + 56)
    linkLineIdx(ctx, nodes, 5 + 56, 6 + 56)
    linkLineIdx(ctx, nodes, 6 + 56, 7 + 56)
    linkLineIdx(ctx, nodes, 7 + 56, 8 + 56)

    linkLineIdx(ctx, nodes, 0 + 56, 2 + 56)
    linkLineIdx(ctx, nodes, 1 + 56, 3 + 56)
    linkLineIdx(ctx, nodes, 5 + 56, 7 + 56)
    linkLineIdx(ctx, nodes, 6 + 56, 8 + 56)

    linkLineIdx(ctx, nodes, 0 + 56, 1 + 56)
    linkLineIdx(ctx, nodes, 1 + 56, 2 + 56)
    linkLineIdx(ctx, nodes, 2 + 56, 3 + 56)
    linkLineIdx(ctx, nodes, 3 + 56, 0 + 56)

    linkLineId(ctx, nodes, 114, 98)

    drawSymbols(ctx)

    ctx.font = "bold 14px Arial"

    let radius = 12
    let bigK = 1.8
    let middleK = 1.3

    let highlightedNodes = []

    nodes.forEach((d, idx) => {
      let isBig = d.size == 3
      let isMiddle = d.size == 2

      if (isBig) ctx.font = "bold 28px Arial"
      else if (isMiddle) ctx.font = "bold 20px Arial"
      else ctx.font = "bold 16px Arial"

      let [x, y] = getPos(d.a, d.d, isBig ? 1.04 : 1)
      d.x = x
      d.y = y
      d.r = isBig ? radius * bigK : isMiddle ? radius * middleK : radius
      if (d.notShow) return
      if (d.color !== "transparent") {

        let strokeStyle
        let lineWidth
        if (d.PIds && d.PIds.length > 0) {
          // highlighted
          highlightedNodes.push(d)
          if (d.PIds.some(i => programNames.find(j => j.PId === i).isKarmicTail))
            strokeStyle = "#ff7575" // "rgb(255,192,192)" red
          else
            strokeStyle = "cornflowerblue" //"#7297d6" blue
          lineWidth = isBig ? 6 : 5
        } else {
          strokeStyle = "black"
          lineWidth = 3
        }

        ctx.beginPath()
        ctx.arc(d.x, d.y, d.r, 0, 2 * Math.PI)
        ctx.strokeStyle = strokeStyle
        ctx.lineWidth = lineWidth
        ctx.stroke()

        // fill circle
        ctx.fillStyle = d.color ? d.color : "white"
        ctx.fill()

        if (d.color) {
          ctx.beginPath()
          ctx.arc(d.x, d.y, d.r - 1, 0, 2 * Math.PI)
          ctx.strokeStyle = "white"
          ctx.lineWidth = 2
          ctx.stroke()
        }
      }

      ctx.lineWidth = 5
      ctx.fillStyle = d.textColor || "black"
      // ctx.fillText(d.id, d.x, d.y + (isBig ? 10 : isMiddle ? 8 : 6)) // d.id for view id
      ctx.fillText(d.value, d.x, d.y + (isBig ? 10 : isMiddle ? 8 : 6))
    })

    ctx.font = "bold 16px Arial"
    ctx.fillStyle = "black"
    let dateText = third ? date1 + ' + ' + date2 : first ? date1 : date2

    if (third) {
      if (name1 || name2) {
        let name = (name1 || "???") + " + " + (name2 || "???")
        ctx.fillText(name, 100, 30)
        ctx.fillText(dateText, 100, 50)
      } else ctx.fillText(dateText, 100, 30)
    } else {
      let name = first ? name1 : name2
      if (name) {
        ctx.fillText(name, 50, 30)
        ctx.fillText(dateText, 50, 50)
      }
      else ctx.fillText(dateText, 50, 30)
    }

    let img = canvas.toDataURL("image/png")

    ref.current.saveImage(img)
    setHighlightedNodes(highlightedNodes)
    setCanvasDom(canvas)
  }, [dd, mm, yy, dd2, mm2, yy2, linkLineIdx, linkLineId, date1, date2, name1, name2])

  useEffect(() => {
    if (!canvasDom) return
    const eventListenerClick = event => handlerClick(event)
    const eventListenerHover = event => handlerMouseHover(event)
    const eventListenerMove = event => handlerMouseMove(event)
    const eventListenerOut = event => handlerMouseOut(event)
    canvasDom.addEventListener('click', eventListenerClick)
    canvasDom.addEventListener('mouseover', eventListenerHover)
    canvasDom.addEventListener('mousemove', eventListenerMove)
    canvasDom.addEventListener('mouseout', eventListenerOut)
    return () => {
      canvasDom.removeEventListener('click', eventListenerClick)
      canvasDom.removeEventListener('mouseover', eventListenerHover)
      canvasDom.removeEventListener('mousemove', eventListenerMove)
      canvasDom.removeEventListener('mouseout', eventListenerOut)
    }
  }, [canvasDom, handlerClick, handlerMouseHover, handlerMouseMove])

  return (
    <div>
      <canvas id="matrix-canvas" ref={refCanvas} width={560} height={560} />
      {selectedNode && selectedNode.name &&
        <div className="noprint hint-box"
          style={{ left: selectedNode.hintX, top: selectedNode.hintY }}>
          {selectedNode.name}
        </div>
      }
    </div>)
}

function checkPosition({ x, y, left, top }, highlightedNodes, setSelectedNode) {

  let nodes = []

  // if (isDev()) console.log("__check position", x + ' ' + y)

  for (let i = 0; i < highlightedNodes.length; i++) {
    let dot = highlightedNodes[i]
    let dx = x - dot.x
    let dy = y - dot.y
    if (dx * dx + dy * dy < dot.r * dot.r) {
      // if (isDev()) console.log(dot)
      nodes.push(dot)
    }
  }
  if (nodes.length > 0) {
    setSelectedNode({
      x, y, name: collectNames(nodes.map(d => d.PIds).flat()),
      hintX: left,
      hintY: top
    })
  }
  else setSelectedNode({})
}

function checkNodes(nodes, id1, id2, id3, isKarmicTail) {

  // if (__DEBUG__) console.log("___ ~ checkNodes ~ checkNodes:", id1, id2, id3)

  let a = nodes.find(d => id1 === d.id)
  let b = nodes.find(d => id2 === d.id)
  let c = nodes.find(d => id3 === d.id)

  let keys = checkTriplet(a.value, b.value, c.value, { fixOrder: isKarmicTail, isKarmicTail }, true)

  let aPIds = !a.PIds ? [] : a.PIds
  let bPIds = !b.PIds ? [] : b.PIds
  let cPIds = !c.PIds ? [] : c.PIds

  a.PIds = [...new Set([...aPIds, ...keys[0]])]
  b.PIds = [...new Set([...bPIds, ...keys[1]])]
  c.PIds = [...new Set([...cPIds, ...keys[2]])]
}

function checkNodes64(nodes, base, isKarmicTail) {
  checkNodes(nodes, base, i64(base + 1), i64(base + 2), isKarmicTail)
}

function checkKeysNodes(nodes) {

  checkNodes64(nodes, 0)  // 0,  65,  66  (45 min)
  checkNodes64(nodes, 8)  // 8,  73,  74  (52 min)
  checkNodes64(nodes, 16) // 16, 81,  82  (0 min)
  checkNodes64(nodes, 24) // 24, 89,  90  (7 min)
  checkNodes64(nodes, 32) // 32, 97,  98  (15 min)
  checkNodes64(nodes, 40) // 40, 105, 106 (22 min)
  checkNodes(nodes, 48,   // 48, 114, 113 (30 min)
    i64(48 + 2), i64(48 + 1), false) //true) // karmic tail // TODO: fix old calculator 
  checkNodes64(nodes, 56) // 56, 121, 122 (37 min)

  checkNodes(nodes, i64(32 + 2), i256(0), i256(1)) // 98,  256, 257
  checkNodes(nodes, i64(48 + 2), i256(0), i256(2)) // 114, 256, 258

  for (let i = 0; i < 64 - 2; i++) {
    checkNodes(nodes, i, i + 1, i + 2)
  }

  return nodes
}

function checkKeysNodesCompatibility(nodes) {
  checkNodes64(nodes, 0)  // 0,  65,  66  (45 min)
  checkNodes64(nodes, 16) // 16, 81,  82  (0 min)
  checkNodes64(nodes, 32) // 32, 97,  98  (15 min)
  checkNodes(nodes, 48,   // 48, 114, 113 (30 min)
    i64(48 + 2), i64(48 + 1), false) // true) // karmic tail 

  checkNodes(nodes, i64(32 + 2), i256(0), i256(1)) // 98,  256, 257
  checkNodes(nodes, i64(48 + 2), i256(0), i256(2)) // 114, 256, 258

  return nodes
}