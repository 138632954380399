import React, { useState, useEffect } from 'react'
import { useAuth } from './useAuth'
import config from './secret_config.json'
import { useNavigate, useParams } from 'react-router-dom'
import { useLocaleContext } from './Locale'

export const UserEdit = () => {
  const { userId } = useParams()
  const { ls } = useLocaleContext()
  const [userData, setUserData] = useState({})
  const [message, setMessage] = useState('')
  const { jwt, isAdmin, isSuperAdmin, statusToText } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${config.apiFolder}get_users.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            jwt,
            userId,
          }),
        })

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const responseData = await response.json()
        if (responseData.status === 'success') {
          let user = responseData.users.filter(i => i.user_id === userId)
          setUserData(user[0])
        } else {
          setMessage(responseData.message)
        }
      } catch (error) {
        setMessage('Failed to fetch user data: ' + error.message)
      }
    }

    if (jwt && (isAdmin || isSuperAdmin)) fetchUserData()
  }, [jwt, userId, isAdmin, isSuperAdmin])

  const handleDeleteRestore = async () => {
    try {
      // Toggle user status between active and deleted
      const updatedStatus = userData.status & 1 ? userData.status & ~1 : userData.status | 1
      const response = await fetch(`${config.apiFolder}user_update.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jwt,
          user_id: userId,
          status: updatedStatus,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const responseData = await response.json()
      if (responseData.status === 'success') {
        setUserData({ ...userData, status: updatedStatus })
        setMessage('User updated successfully!')
      } else {
        setMessage(responseData.message)
      }
    } catch (error) {
      setMessage('Failed to update user: ' + error.message)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setUserData({ ...userData, [name]: value })
  }

  const handleDeleteRestoreClick = async () => {
    if (window.confirm(
      (userData.status & 1)
        ? ls(`Are you sure you want to restore the user ${userData.name}?`, `Möchten Sie den Benutzer ${userData.name} wirklich wiederherstellen?`, `Вы уверены, что хотите восстановить пользователя ${userData.name}?`)
        : ls(`Are you sure you want to delete the user ${userData.name}?`, `Möchten Sie den Benutzer ${userData.name} wirklich löschen?`, `Вы уверены, что хотите удалить пользователя ${userData.name}?`))) {
      await handleDeleteRestore()
    }
  }

  if (!isAdmin && !isSuperAdmin) return <p>No admin permissions</p>

  return (
    <div>
      <br />
      <button onClick={() => navigate(-1)}>{ls("Back", "Zurück", "Назад")}</button>
      <h1>{ls("Edit User", "Benutzer bearbeiten", "Редактирование пользователя")}</h1>
      {message && <p>{message}</p>}
      <form style={{ display: 'flex', flexDirection: 'column', gap: '16px', maxWidth: '300px' }}>

        {isSuperAdmin && <label>
          Id:
          <input type="text" name="name" value={userData.user_id || ''} onChange={handleChange} readOnly style={{ width: '100%' }} />
        </label>
        }

        <label>
          {ls("Name", "Name", "Имя")}:
          <input type="text" name="name" value={userData.name || ''} onChange={handleChange} readOnly style={{ width: '100%' }} />
        </label>

        {userData.fullname && <label>
          {ls("Fullname", "Vollständiger Name", "Полное имя")}:
          <input type="text" name="fullname" value={userData.fullname || ''} onChange={handleChange} readOnly style={{ width: '100%' }} />
        </label>
        }

        <label>
          {ls("Email", "E-Mail", "Электронная почта")}:
          <input type="email" name="email" value={userData.email || ''} onChange={handleChange} readOnly style={{ width: '100%' }} />
        </label>

        <label>
          {ls("Status", "Status", "Статус")}:
          <input type="text" name="status" value={statusToText(userData.status)} readOnly style={{ width: '100%' }} />
        </label>

        <button type="button"
          onClick={handleDeleteRestoreClick} style={{ alignSelf: 'flex-start' }} disabled={userData.status & (3 << 6)}>
          {(userData.status & 1)
            ? ls("Restore user", "Benutzer wiederherstellen", "Восстановить пользователя")
            : ls("Delete user", "Entfernen Benutzer", "Удалить пользователя")}
        </button>
      </form>
    </div>
  )
}
