import React from 'react'

function About() {
  return (
    <div>
      <h3>About us</h3>
      <p>Our company</p>
    </div>
  )
}

export default About