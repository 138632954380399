class SerializeTab {
}

SerializeTab.storage = []
SerializeTab.iterator = 0

SerializeTab.storage2 = []
SerializeTab.iterator2 = 0

export function cleanSerializeTabFirst() {
  SerializeTab.storage = []
  SerializeTab.iterator = 0
}

export function cleanSerializeTabSecond() {
  SerializeTab.storage2 = []
  SerializeTab.iterator2 = 0
}

export function addToSerializeTabFirst(value) {

  if (typeof value === "object") {
    SerializeTab.storage.push(value)
    return value.value
  }

  SerializeTab.storage.push({ value })
  return value
}

export function addToSerializeTabSecond(value) {

  if (typeof value === "object") {
    SerializeTab.storage2.push(value)
    return value.value
  }

  SerializeTab.storage2.push({ value })
  return value
}

export function getSerializeTabFirst() {
  return SerializeTab.storage[SerializeTab.iterator++]
}

export function getSerializeTabSecond() {
  return SerializeTab.storage2[SerializeTab.iterator2++]
}

export function resetIteratorFirst() {
  SerializeTab.iterator = 0
}

export function resetIteratorSecond() {
  SerializeTab.iterator2 = 0
}

export function saveImageFirst(image) {
  SerializeTab.image = image
}

export function saveImageSecond(image) {
  SerializeTab.image2 = image
}

export function saveImageThird(image) {
  SerializeTab.image3 = image
}

export function getImageFirst() {
  return SerializeTab.image
}

export function getImageSecond() {
  return SerializeTab.image2
}
