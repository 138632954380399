import React, { useState, useEffect } from 'react'
import { useAuth } from './useAuth'
import config from './secret_config.json'
import { useLocaleContext } from './Locale'

export const UserActivity = ({ userId }) => {
  const { jwt } = useAuth()
  const { ls } = useLocaleContext()
  const [historyData, setHistoryData] = useState([])
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [showUserAgent, setShowUserAgent] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)

  const [dateRange, setDateRange] = useState(() => {
    return localStorage.getItem('userActivityDateRange') || 'week'
  })

  const handleDateRangeChange = (e) => {
    const newValue = e.target.value
    setDateRange(newValue)
    localStorage.setItem('userActivityDateRange', newValue)
  }

  useEffect(() => {
    const fetchUserHistory = async () => {
      setLoading(true)
      try {
        let startDate, endDate
        const today = new Date()

        switch (dateRange) {
          case 'day':
            startDate = endDate = today.toISOString().split('T')[0]
            break
          case 'week':
            startDate = new Date(today.setDate(today.getDate() - 7)).toISOString().split('T')[0]
            endDate = new Date().toISOString().split('T')[0]
            break
          case 'month':
            startDate = new Date(today.setMonth(today.getMonth() - 1)).toISOString().split('T')[0]
            endDate = new Date().toISOString().split('T')[0]
            break
          case 'year':
            startDate = new Date(today.setFullYear(today.getFullYear() - 1)).toISOString().split('T')[0]
            endDate = new Date().toISOString().split('T')[0]
            break
          default:
            startDate = endDate = today.toISOString().split('T')[0]
        }

        const response = await fetch(`${config.apiFolder}user_history.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            jwt,
            userId,
            startDate,
            endDate,
          }),
        })

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const responseData = await response.json()
        if (responseData.status === 'success') {
          setHistoryData(responseData.history)
        } else {
          setMessage(responseData.message)
        }
      } catch (error) {
        setMessage('Failed to fetch user history: ' + error.message)
      } finally {
        setLoading(false)
      }
    }

    if (jwt && userId) {
      fetchUserHistory()
    }
  }, [jwt, userId, dateRange])

  const getDeviceColor = (device, os, browser) => {
    const hash = `${device}-${os}-${browser}`.split('').reduce(
      (acc, char) => char.charCodeAt(0) + ((acc << 4) - acc), 0
    )
    const hue = 40 + (hash % 160)
    return `hsl(${hue}, 85%, 85%)`
  }

  const prepareHistogramData = () => {
    const data = new Map()

    historyData.forEach(entry => {
      const date = entry.timestamp.split(' ')[0]
      const eventCount = (entry.event.login || 0) + (entry.event.update || 0)
      data.set(date, (data.get(date) || 0) + eventCount)
    })

    return Array.from(data.entries()).sort((a, b) => a[0].localeCompare(b[0]))
  }

  const filteredHistoryData = selectedDate
    ? historyData.filter(entry => entry.timestamp.split(' ')[0] === selectedDate)
    : historyData

  const handleBarClick = (date) => {
    setSelectedDate(date)
  }

  const handleResetFilter = () => {
    setSelectedDate(null)
  }

  return (
    <div>
      <h2>{ls("User Activity", "Benutzeraktivität", "Активность пользователя")}</h2>
      {message && <p>{message}</p>}

      {loading ? (
        <p>{ls("Loading...", "Wird geladen...", "Загрузка...")}</p>
      ) : (
        <>
          <div style={{ 
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <div>
              {!selectedDate && (
                <label>
                  {ls("Select Date Range:", "Datumsbereich auswählen:", "Выберите диапазон дат:")}
                  <select value={dateRange} onChange={handleDateRangeChange}>
                    <option value="day">{ls("Today", "Heute", "Сегодня")}</option>
                    <option value="week">{ls("Last Week", "Letzte Woche", "Последняя неделя")}</option>
                    <option value="month">{ls("Last Month", "Letzter Monat", "Последний месяц")}</option>
                    <option value="year">{ls("Last Year", "Letztes Jahr", "Последний год")}</option>
                  </select>
                </label>
              )}
              {selectedDate && (
                <div style={{ display: 'inline-block' }}>
                  <button
                    onClick={handleResetFilter}
                    style={{ marginLeft: '16px' }}
                  >
                    {ls("Reset Date Filter", "Datumsfilter zurücksetzen", "Сбросить фильтр даты")}
                  </button>
                  <span style={{ marginLeft: '8px' }}>
                    ({ls("Selected", "Ausgewählt", "Выбрано")}: {selectedDate})
                  </span>
                </div>
              )}
            </div>
            <label>
              <input
                type="checkbox"
                checked={showUserAgent}
                onChange={(e) => setShowUserAgent(e.target.checked)}
              />
              {ls("Show User Agent", "User Agent anzeigen", "Показать User Agent")}
            </label>
          </div>

          <table border="1" style={{ marginTop: '16px', width: '100%' }}>
            <thead>
              <tr>
                <th>Timestamp</th>
                {showUserAgent && <th>HTTP User Agent</th>}
                <th>Device</th>
                <th>OS</th>
                <th>Browser</th>
                <th>Remote Address</th>
                <th>Logins</th>
                <th>Updates</th>
              </tr>
            </thead>
            <tbody>
              {filteredHistoryData.length > 0 ? (
                filteredHistoryData.map((entry, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: getDeviceColor(entry.device, entry.os, entry.browser)
                    }}
                  >
                    <td>{entry.timestamp}</td>
                    {showUserAgent && <td>{entry.event.HTTP_USER_AGENT}</td>}
                    <td>{entry.device}</td>
                    <td>{entry.os}</td>
                    <td>{entry.browser}</td>
                    <td>{entry.event.REMOTE_ADDR}</td>
                    <td>{entry.event.login || 0}</td>
                    <td>{entry.event.update || 0}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={showUserAgent ? "8" : "7"}>
                    {ls("No activity found for the selected range.", 
                        "Keine Aktivitäten im ausgewählten Zeitraum gefunden.", 
                        "Активность за выбранный период не найдена.")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {historyData.length > 0 && (
            <div style={{ marginTop: '24px' }}>
              <h3>
                {ls("Activity Histogram", "Aktivitätshistogramm", "Гистограмма активности")}
                {selectedDate && ` (${ls("Selected", "Ausgewählt", "Выбрано")}: ${selectedDate})`}
              </h3>
              <div style={{
                display: 'flex',
                alignItems: 'flex-end',
                height: '100px',
                gap: '2px',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px'
              }}>
                {prepareHistogramData().map(([date, count], index) => {
                  const height = `${Math.max(20, (count / Math.max(...Array.from(prepareHistogramData().map(d => d[1])))) * 100)}%`
                  return (
                    <div
                      key={date}
                      onClick={() => handleBarClick(date)}
                      style={{
                        flex: 1,
                        height,
                        backgroundColor: date === selectedDate
                          ? `hsl(${40 + (index * 10) % 160}, 95%, 75%)`  // Brighter color for selected day
                          : `hsl(${40 + (index * 10) % 160}, 85%, 85%)`,
                        position: 'relative',
                        minWidth: '20px',
                        cursor: 'pointer',
                      }}
                      title={`${date}: ${count} ${ls("events", "Ereignisse", "событий")}`}
                    >
                      <div style={{
                        position: 'absolute',
                        bottom: '-20px',
                        left: '50%',
                        transform: 'translateX(-50%) rotate(-45deg)',
                        fontSize: '10px',
                        whiteSpace: 'nowrap'
                      }}>
                        {date}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}