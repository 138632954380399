import React, { useState } from "react"
import { useLocaleContext } from "../Locale"
import { UndefinedComponemt } from "./Components"
import { useDate } from "./useDate"
import { Dialog } from "./Dialog"
import { useAuth } from "../useAuth"

const EditDateButton = ({ first }) => {
  const { date1, date2 } = useDate()
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [dialogKey, setDialogKey] = useState(0)

  const date = first ? date1 : date2

  const openDialog = () => {
    setDialogOpen(true)
    setDialogKey((prevKey) => prevKey + 1)
  }

  const closeDialog = () => {
    setDialogOpen(false)
  }

  return (
    <div>
      {isDialogOpen && <dialog id="dialog" key={dialogKey} open>
        <Dialog closeDialog={closeDialog} />
      </dialog>}
      <div className="horiz-container">
        <input
          type="text"
          value={date}
          readOnly
          onClick={openDialog}
          style={{
            width: '100px',
            cursor: 'pointer',
            border: '1px solid #ccc',
            marginRight: 0,
            borderRadius: '4px',
          }}
        />
        <button className="coolButton" type="button" onClick={openDialog} style={{ marginLeft: 0 }}>✏️</button>
      </div>
    </div>
  )
}

const ShowDate2Button = () => {
  let { setSingle } = useDate()
  let { ls } = useLocaleContext()
  return (
    <button onClick={() => setSingle(false)}>
      {ls("show second date >>", "zweites Datum anzeigen >>", "показать вторую дату >>")}
    </button>
  )
}

const HideDate2Button = () => {
  let { setSingle } = useDate()
  let { ls } = useLocaleContext()
  return (
    <button onClick={() => setSingle(true)}>
      {ls("hide second date <<", "zweites Datum ausblenden <<", "спрятать вторую дату <<")}
    </button>
  )
}

const SwapDatesButton = () => {
  let { swapDate } = useDate()
  let { ls } = useLocaleContext()
  return (
    <button style={{ marginRight: 20 }} onClick={() => swapDate()}>
      {ls("<< swap dates >>", "<< Daten tauschen >>", "<< поменять даты местами >>")}
    </button>
  )
}

const ReportButton = () => {
  let { ls } = useLocaleContext()
  const clickHandler = () => {
    // docxReport({ _page: ls("Page", "Site", "Страница"), _of: ls("of", "von", "из"), first })
    window.print()
  }
  return (
    <button onClick={clickHandler}>
      {/* &#8659; .docx (ms-word) */}
      {ls("print", "druck", "печать")}
    </button>
  )
}

export const ButtonsBlock = ({ first, second }) => {
  let { isSingle, isDouble } = useDate()
  let { isFullUser } = useAuth()
  if (first)
    return (
      <div className="horiz-container">
        <EditDateButton first />
        {isSingle && isFullUser && <ShowDate2Button />}
        {isDouble && <HideDate2Button />}
        {isDouble && <SwapDatesButton />}
        <ReportButton />
      </div>
    )

  if (second)
    return (
      <div className="horiz-container">
        <EditDateButton second />
        {/* <ShowDate2Button /> */}
        <HideDate2Button />
        <SwapDatesButton />
        {/* <ReportButton /> */}
      </div>
    )

  return <UndefinedComponemt name="ButtonsBlock" />
}