import React, { useState, useCallback, useMemo } from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import About from './About'
import Contacts from './Contacts'
import { LocaleProvider } from "./Locale"
import { AuthProvider } from './useAuth'
import { Content } from './Content'
import { AdminPage } from './Admin'
import { UserEdit } from './UserEdit'

function getLocale() {
  return localStorage.getItem('locale') || "de"
}

const App = () => {
  let [locale, _setLocale] = useState(getLocale)

  let setLocale = useCallback(locale => {
    _setLocale(locale)
    localStorage.setItem('locale', locale)
  }, [])

  let ls = useCallback((en, de, ru) => {
    if (locale === "ru") {
      if (!ru) return en
      return ru
    }
    if (locale === "de") {
      if (!de) return en
      return de
    }
    return en
  }, [locale])

  let localeValue = useMemo(() => ({ locale, setLocale, ls }), [locale, setLocale, ls])

  return (
    <LocaleProvider value={localeValue}>
      <AuthProvider>
        <Router>
          <div className="app">
            <Header />
            <main>
              <Routes>
                <Route path="/" element={<Content />} />
                <Route path="/about" element={<About />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/admin/:userId" element={<UserEdit />} />
              </Routes>
            </main>
            <Footer />
          </div>
        </Router>
      </AuthProvider>
    </LocaleProvider>
  )
}

export default App
