import './init'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('./sw.js')
//       .then((registration) => {
//         console.log('Service Worker зарегистрирован: ', registration);
//       })
//       .catch((registrationError) => {
//         console.log('Ошибка регистрации Service Worker: ', registrationError);
//       });
//   });
// }

const root = createRoot(document.getElementById('root'))
root.render(<App />)