import React, { useEffect, useState } from 'react'
import { useAuth } from './useAuth'
import config from './secret_config.json'
import { useNavigate } from 'react-router-dom'
import { useLocaleContext } from './Locale'

export const AdminPage = () => {
  const { isAdmin, isSuperAdmin } = useAuth()
  const { ls } = useLocaleContext()

  if (!isAdmin && !isSuperAdmin) return <p>No admin permissions</p>

  return (
    <div>
      <h1>{ls("Admin page", "Admin-Seite", "Страница администратора")}</h1>
      <Invite />
      <GetUsers />
    </div>
  )
}

const Invite = () => {
  const [email, setEmail] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [message, setMessage] = useState('')
  const { jwt } = useAuth()
  const { ls } = useLocaleContext()

  const handleSend = async () => {
    try {
      const response = await fetch(`${config.apiFolder}gen_reg_link.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jwt,
          email,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const responseData = await response.json()
      setMessage(responseData.message)
    } catch (error) {
      setMessage('Failed to generate registration link: ' + error.message)
    }
  }

  // Function to validate email
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (e) => {
    const newEmail = e.target.value
    setEmail(newEmail)
    setIsValidEmail(validateEmail(newEmail))
  }

  let sendButtonText = ls("Send link", "Link senden", "Отправить ссылку")

  return (
    <div style={{ marginBottom: '2rem', padding: '1rem', border: '1px solid #ccc', borderRadius: '8px' }}>
      <h2>{ls("Invite a New User", "Neuen Benutzer einladen", "Приглашение нового пользователя")}</h2>
      <p>
        {ls(
          `Generate a registration link for the New User. Please enter a valid email address of New User. After clicking „${sendButtonText}“, the user will receive an email with a link to complete their registration.`,
          `Generieren Sie einen Registrierungslink für den neuen Benutzer. Bitte geben Sie eine gültige E-Mail-Adresse des neuen Benutzers ein. Nach dem Klick auf „${sendButtonText}“ erhält der Benutzer eine E-Mail mit einem Link zur Vervollständigung der Registrierung.`,
          `Сгенерируйте ссылку для регистрации нового пользователя. Пожалуйста, введите корректный адрес электронной почты нового пользователя. После нажатия на «${sendButtonText}», пользователь получит письмо с ссылкой для завершения регистрации.`
        )}
      </p>
      <input
        type="text"
        placeholder={ls("email of user", "Email von Benutzer", "Емайл пользователя")}
        value={email}
        onChange={handleEmailChange}
      />
      <button onClick={handleSend} disabled={!isValidEmail}>{sendButtonText}</button>
      {message && <p>{message}</p>}
    </div>
  )
}

const GetUsers = () => {
  const [message, setMessage] = useState('')
  const [users, setUsers] = useState([])
  const { jwt, StatusToText, isSuperAdmin } = useAuth()
  const { ls } = useLocaleContext()
  const navigate = useNavigate()

  useEffect(() => { handleGetUsers() }, [])

  const handleGetUsers = async () => {
    try {
      const response = await fetch(`${config.apiFolder}get_users.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jwt,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const responseData = await response.json()
      if (responseData.status === 'success') {
        setUsers(responseData.users)
      } else {
        setMessage(responseData.message)
      }
    } catch (error) {
      setMessage('Failed to fetch users: ' + error.message)
    }
  }

  const handleEditUser = (userId) => {
    navigate(`/admin/${userId}`)
  }

  return (
    <div style={{ marginTop: '2rem', padding: '1rem', border: '1px solid #ccc', borderRadius: '8px' }}>
      <h2>{ls("Users List", "Benutzer Liste", "Список пользователей")}</h2>
      {message && <p>{message}</p>}
      <button onClick={handleGetUsers}>
        {ls("Get Users / Refresh", "Benutzer abrufen / Aktualisieren", "Получить пользователей / Обновить")}
      </button>
      {users.length > 0 && (
        <div>
          <table border="1">
            <thead>
              <tr>
                <th></th>
                {isSuperAdmin && <th>ID</th>}
                <th>Name</th>
                <th>Fullname</th>
                <th>Email</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.user_id}>
                  <td>
                    <button onClick={() => handleEditUser(user.user_id)}>
                      ✏️
                    </button>
                  </td>
                  {isSuperAdmin && <td>{user.user_id}</td>}
                  <td>{user.name}</td>
                  <td>{user.fullname}</td>
                  <td>{user.email}</td>
                  <td><StatusToText status={user.status} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
