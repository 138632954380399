import React from 'react'
import "./Calculator.less"
// import "./index.css"
import { MatrixBox } from './MatrixBox'
import { useLocaleContext } from '../Locale'
import { MainDataTable } from './Tables/MainDataTable'
import { MainDataThirdTable } from './Tables/MainDataThirdTable'
import { YearlySummaryTable } from './Tables/YearlySummaryTable'
import { ButtonsBlock } from './Buttons'
import { DateProvider, useDate } from "./useDate"
import { ChakraDataTable } from './Tables/ChakraDataTable'
import { cn } from '../classnames'

export const Calculator = () => {
  return (
    <DateProvider>
      <CalculatorContent />
    </DateProvider>
  )
}

const CalculatorContent = () => {

  let { isSingle, isDouble } = useDate()
  let { ls } = useLocaleContext()

  return (
    <div className="horiz-container">
      <div className={cn({ 'vert-container': true, 'frame-first': isDouble })}>
        <div className='space-down'>
          <ButtonsBlock first />
        </div>
        <div className='horiz-container'>
          <div className='space-right-down'>
            <MainDataTable first />
          </div>
          <div className='space-right-down'>
            <ChakraDataTable first />
          </div>
          <div className='space-right-down'>
            <YearlySummaryTable first />
          </div>
          <div className='new-page'>
            <MatrixBox first />
          </div>
        </div>
      </div>

      {isDouble &&
        <div className='vert-container frame-second new-page'>
          <div className='space-down'>
            <ButtonsBlock second />
          </div>
          <div className='horiz-container'>
            <div className='space-right-down'>
              <MainDataTable second />
            </div>
            <div className='space-right-down'>
              <ChakraDataTable second />
            </div>
            <div className='space-right-down'>
              <YearlySummaryTable second />
            </div>
            <div className='new-page'>
              <MatrixBox second />
            </div>
          </div>
        </div>
      }

      {isDouble &&
        <div className='vert-container new-page' style={{ textAlign: "center" }}>
          <h1>
            {ls("Сompatibility", "Kompatibilität", "Совместимость")}
          </h1>
          <div className='horiz-container'>
            <div className='space-right-down'>
              <MainDataThirdTable second />
            </div>
            <div>
              <MatrixBox third />
            </div>
          </div>
        </div>
      }

    </div>
  )
}