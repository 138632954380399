import React from "react"
import { InputWithError } from "./InputWithError"
import { useLocaleContext } from "../Locale"
import { UndefinedComponemt, useRefresh } from "./Components"
import { useDate } from "./useDate"

const RenewButton = () => {
  let { ls } = useLocaleContext()
  let refresh = useRefresh()
  return (
    <button className="coolButton" type="button" onClick={() => refresh()} >
      {ls("renew", "renew", "обновить")}
    </button>
  )
}

const ShowDate2Button = () => {
  let { setSingle } = useDate()
  let { ls } = useLocaleContext()
  return (
    <button onClick={() => setSingle(false)}>
      {ls("show second date >>", "zweites Datum anzeigen >>", "показать вторую дату >>")}
    </button>
  )
}

const HideDate2Button = () => {
  let { setSingle } = useDate()
  let { ls } = useLocaleContext()
  return (
    <button onClick={() => setSingle(true)}>
      {ls("hide second date <<", "zweites Datum ausblenden <<", "спрятать вторую дату <<")}
    </button>
  )
}

const SwapDatesButton = () => {
  let { swapDate } = useDate()
  let { ls } = useLocaleContext()
  return (
    <button style={{ marginRight: 20 }} onClick={() => swapDate()}>
      {ls("<< swap dates >>", "<< Daten tauschen >>", "<< поменять даты местами >>")}
    </button>
  )
}

const ReportButton = () => {
  let { ls } = useLocaleContext()
  const clickHandler = () => {
    // docxReport({ _page: ls("Page", "Site", "Страница"), _of: ls("of", "von", "из"), first })
    window.print()
  }
  return (
    <button onClick={clickHandler}>
      {/* &#8659; .docx (ms-word) */}
      {ls("print", "druck", "печать")}
    </button>
  )
}

export const ButtonsBlock = ({ first, second }) => {
  let { isSingle, isDouble } = useDate()
  if (first)
    return (
      <div>
        <InputWithError first />
        <RenewButton />
        {isSingle && <ShowDate2Button />}
        {isDouble && <HideDate2Button />}
        {isDouble && <SwapDatesButton />}
        <ReportButton />
      </div>
    )

  if (second)
    return (
      <div>
        <InputWithError second />
        <RenewButton />
        {/* <ShowDate2Button /> */}
        <HideDate2Button />
        <SwapDatesButton />
        {/* <ReportButton /> */}
      </div>
    )

  return <UndefinedComponemt name="ButtonsBlock" />
}