{
  "name": "matrix-tb",
  "version": "1.0.1",
  "main": "index.js",
  "repository": "https://github.com/MozgOFF215/matrix-tb.git",
  "license": "Apache-2.0",
  "private": true,
  "workspaces": [
    "packages/*"
  ],
  "scripts": {
    "dev": "node build.mjs dev",
    "build-version": "npm version patch && node build.mjs prod",
    "build": "node build.mjs prod",
    "lint": "eslint src",
    "analyse": "esbuild-visualizer --open --filename ./dist/stats.html --metadata ./dist/meta.json",
    "deploy": "yarn build && node deploy.js"
  },
  "devDependencies": {
    "@eslint/js": "^9.13.0",
    "esbuild": "^0.19.3",
    "esbuild-plugin-copy": "^2.1.1",
    "esbuild-plugin-filelastmodified": "^2.0.4",
    "esbuild-plugin-less": "^1.2.4",
    "esbuild-plugin-svgr": "^2.1.0",
    "esbuild-visualizer": "^0.4.1",
    "eslint": "^9.13.0",
    "eslint-plugin-react": "^7.37.2",
    "globals": "^15.11.0"
  },
  "dependencies": {
    "clsx": "^2.0.0",
    "history": "^4.10.1",
    "prop-types": "^15.8.1",
    "react": "18.1.0",
    "react-dom": "18.1.0",
    "react-input-mask": "^2.0.4",
    "react-router": "^5.2.0",
    "react-router-dom": "^6.27.0",
    "react-scroll": "^1.9.0",
    "ssh2-sftp-client": "^11.0.0"
  },
  "packageManager": "yarn@4.5.1"
}
