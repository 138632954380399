import React, { useEffect, useState } from 'react'
import { checkDate } from './Utils'
import { cn } from '../classnames'
import { useLocaleContext, localize } from '../Locale'
import './Dialog.less'
import { useAuth } from '../useAuth'
import InputMask from 'react-input-mask'
import { useDate } from './useDate'

const InputWithError = localize(({ texts, onChange, text, onKeyDown, disabled = false }) => {

  const [alarm, setAlarm] = useState()

  useEffect(() => {
    if (!checkDate(text)) setAlarm(texts.invalidDate)
    else setAlarm()
  }, [text])

  return <>
    <InputMask mask="99.99.9999" maskPlaceholder="DD.MM.YYYY" alwaysShowMask={false} onChange={(e) => onChange(e.target.value)} value={text}>
      {() => <input type="text" placeholder={texts.placeholder} style={{ width: 100 }} />}
    </InputMask>
    <div className={cn({ "validation-message": true, "i-error": alarm && !disabled })}>{alarm}</div>
  </>
})

InputWithError.defaultProps = {
  texts: {
    placeholder: "dd.mm.yyyy",
    invalidDate: "Invalid date. Expected format dd.mm.yyyy",
  },
  "texts-de": {
    placeholder: "TT.MM.JJJJ",
    invalidDate: "Ungültiges Datum. Erwartetes Format TT.MM.JJJJ",
  },
  "texts-ru": {
    placeholder: "дд.мм.гггг",
    invalidDate: "Неверная дата. Ожидаемый формат дд.мм.гггг",
  }
}

export const Dialog = ({ closeDialog }) => {
  const { isLogin, isFullUser } = useAuth()
  const { ls } = useLocaleContext()

  const {
    date1: dateInit, date2: dateInit2, setDate1: setDate1loc, setName1: setName1loc,
    name1: nameInit, name2: nameInit2, setDate2: setDate2loc, setName2: setName2loc,
    isDouble: doubleInit, setDouble: setDoubleloc
  } = useDate()

  const [date, setDate] = useState(dateInit)
  const [name, setName] = useState(nameInit)

  const [date2, setDate2] = useState(dateInit2)
  const [name2, setName2] = useState(nameInit2)

  const [double, setDouble] = useState(doubleInit)

  const apply = () => {
    setDate1loc(date)
    setDate2loc(date2)
    setName1loc(name)
    setName2loc(name2)
    setDoubleloc(double)
    closeDialog()
  }

  const checkBoxHandle = () => { setDouble(!double) }

  const exchange = () => {
    let sdate1 = date
    let sdate2 = date2
    let sname1 = name
    let sname2 = name2
    setName(sname2)
    setName2(sname1)
    setDate(sdate2)
    setDate2(sdate1)
  }

  console.log(date, date2)

  return (
    <div className="vert-container justify-space-between" style={{ height: "100%" }}>
      <div className="horiz-container">
        <table id="dialog-table">
          <tbody>
            <tr>
              <td>{ls("Name", "Name", "Имя")}</td>
              <td><input onChange={(e) => setName(e.target.value)} style={{ width: 100 }} value={name} /></td>
            </tr>
            <tr>
              <td>{ls("Date", "Datum", "Дата")}</td>
              <td><InputWithError text={date} placeholder={"texts.placeholder"} onChange={setDate} /></td>
            </tr>
            <tr>
              <td><input type="checkbox" checked={double && isLogin} onChange={checkBoxHandle} disabled={!isLogin || !isFullUser} /></td>
              <td>{ls("second date", "zweites Date", "вторая дата")}</td>
              <td><button className='enter-data' onClick={exchange} disabled={!isFullUser}>&#8693;</button></td>
            </tr>
            <tr>
              <td>{ls("Name", "Name", "Имя")}</td>
              <td><input onChange={(e) => setName2(e.target.value)} style={{ width: 100 }} value={name2} disabled={!double || !isLogin} /></td>
            </tr>
            <tr>
              <td>{ls("Date", "Datum", "Дата")}</td>
              <td><InputWithError text={date2} placeholder={"texts.placeholder"} onChange={setDate2} disabled={!double || !isLogin} /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="horiz-container justify-space-between dialog-text">
        <button onClick={apply} className='enter-data'>{ls("Apply", "Anwenden", "Применить")}</button>
        <button onClick={closeDialog} className='enter-data'>{ls("Cancel", "Abbrechen", "Отмена")}</button>
      </div>
    </div>
  )
}