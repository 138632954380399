import React from 'react'

function Contacts() {
  //////////////
  return <h3>Contacts</h3>
  return (
    <div className='logo-container'>
      <img src="./20231008_150841_0000 (2)_cr.png" alt="Home" width={300} className='logo-img'/>
      <h1 className='logo-text'>
        name of owner
      </h1>
    </div>
  )
}

export default Contacts