import React, { createContext, useContext, useState } from "react"

const DateContext = createContext()

export const DateProvider = ({ children }) => {
  const isValidDate = (date) => /^\d{2}\.\d{2}\.\d{4}$/.test(date)

  const [date1, setDate1State] = useState(() => {
    const storedDate = localStorage.getItem("defDate") || "01.01.2001"
    return isValidDate(storedDate) ? storedDate : "01.01.2001"
  })

  const [date2, setDate2State] = useState(() => {
    const storedDate = localStorage.getItem("defDate2") || "02.02.2002"
    return isValidDate(storedDate) ? storedDate : "02.02.2002"
  })

  const [single, setSingleState] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem("singleDate") || "true")
    } catch {
      return true
    }
  })


  const setDate1 = (text) => {
    if (isValidDate(text)) {
      localStorage.setItem("defDate", text)
      setDate1State(text)
    }
  }

  const setDate2 = (text) => {
    if (isValidDate(text)) {
      localStorage.setItem("defDate2", text)
      setDate2State(text)
    }
  }

  const setSingle = (value) => {
    const newValue = JSON.stringify(value)
    localStorage.setItem("singleDate", newValue)
    setSingleState(value)
  }

  const swapDate = () => {
    setDate1(date2)
    setDate2(date1)
  }

  return (
    <DateContext.Provider value={{ date1, date2, setDate1, setDate2, isSingle: single, isDouble: !single, setSingle, swapDate, isValidDate }}>
      {children}
    </DateContext.Provider>
  )
}

export const useDate = () => useContext(DateContext)
