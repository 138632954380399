import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import config from './secret_config.json'

const AuthContext = createContext({})

// decoding JWT token
const decodeJwt = (token) => {
  if (!token) return null
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    if (__DEBUG__) console.log("decoded JWT : ", jsonPayload)
    return JSON.parse(jsonPayload)
  } catch (e) {
    return null
  }
}

// decoding status
const decodeStatus = (status) => {
  if (status == null || status == undefined) return {}

  let isSuperAdmin = !!(status & (1 << 7))
  let isAdmin = !!(status & (1 << 6))
  let isLogin = true
  let isDeleted = !!(status & 1)

  let result = {
    isLogin,
    isSuperAdmin,
    isAdmin,
    isDeleted
  }

  if (__DEBUG__) console.log("decoded status : ", result)

  return result
}

export const AuthProvider = ({ children }) => {
  AuthProvider.propTypes = { children: PropTypes.node.isRequired }
  
  const [data, setData] = useState({})

  const login = (jwtToken) => {
    const decoded = decodeJwt(jwtToken)

    if (decoded) {
      setData({ ...decoded, ...decodeStatus(decoded.status), jwt: jwtToken })
      localStorage.setItem('jwt', jwtToken)
    }
  }

  const statusToText = (status) => {
    let _isDeleted = !!(status & 1)
    let _isSuperAdmin = !!(status & (1 << 7))
    let _isAdmin = !!(status & (1 << 6))
    let result = _isDeleted ? " deleted" : ""
    if (_isSuperAdmin && data.isSuperAdmin) return "super-admin" + result
    if (_isSuperAdmin || _isAdmin) return "admin" + result
    return "user" + result
  }

  const logout = () => {
    setData({})
    localStorage.removeItem('jwt')
  }

  useEffect(() => {
    const storedToken = localStorage.getItem('jwt')
    if (storedToken) {
      const updateJwt = async () => {
        try {
          const response = await fetch(`${config.apiFolder}update.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ jwt: storedToken }),
          })

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
          }

          const responseData = await response.json()
          if (responseData.status === 'success' && responseData.jwt) {
            login(responseData.jwt)
          }
        } catch (error) {
          console.error('Failed to update JWT:', error)
          logout()
        }
      }

      updateJwt()
    }
  }, [])

  const contextValue = { ...data, login, logout, statusToText }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
