import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import './Login.less'
import { useAuth } from './useAuth'
import { LoginComponent} from './LoginComponent'

const LoginButton = ({ onClick }) => {
  const { name, fullname, isLogin } = useAuth()

  const generateInitials = (name) => {
    const initials = name
      .split(' ')
      .map((word) => word.charAt(0))
      .join('')

    return initials.slice(0, 2).toUpperCase()
  }

  return <button onClick={onClick}>
    {!isLogin && false && 
      <svg className='svg-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
        strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
        style={{ stroke: isLogin ? 'lightgreen' : 'currentColor' }}>
        <circle cx="11" cy="7" r="4"></circle>
        <path d="M17 21v-1a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v1"></path>
      </svg>
    }
    {isLogin &&
      <svg className='svg-icon' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="11.6" fill="blue" stroke="#fff" strokeWidth="1" />
        <text x="50%" y="50%" textAnchor="middle" dy="0.35em" fill="#fff" fontSize="13">
          {generateInitials(fullname == null || fullname === undefined || fullname.trim() === '' ? name : fullname)}
        </text>
      </svg>
    }
  </button>
}
LoginButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

const Login = () => {
  const [menuVisible, setMenuVisible] = useState(false)
  const menuRef = useRef()
  const { isLogin } = useAuth()

  const handleClickOutside = (event) => {
    const clickedId = event.target.id
    if (menuRef.current && !menuRef.current.contains(event.target) && clickedId != "save-button" && clickedId != "edit-button") {
      setMenuVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div className="login-menu" ref={menuRef}>

      <LoginButton onClick={() => setMenuVisible(!menuVisible)} />

      {menuVisible && isLogin && <LoginComponent className="login-menu-content"/>}

    </div>
  )
}

export default Login