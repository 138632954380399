import React, { useState } from 'react'
import config from './secret_config.json'
import { useAuth } from './useAuth'
import { useLocaleContext } from './Locale'

export const LoginComponent = () => {
  const { isLogin } = useAuth()
  if (isLogin) return <UserInfoComponent />
  return <Login />
}

const UserInfoComponent = () => {
  const [message, setMessage] = useState('')
  const { name, fullname, logout, email, isDeleted, isAdmin, isSuperAdmin } = useAuth()
  const { ls } = useLocaleContext()

  const handleLogout = () => {
    logout()
    setMessage('Logged out successfully.')
  }

  return (
    <div className="login-menu-content">
      <p>{ls("User", "Benutzer", "Пользователь")}:&nbsp;{name}</p>
      {fullname && <p>{ls("Fullname", "Vollname", <span>Полное&nbsp;имя</span>)}:&nbsp;{fullname.replace(/\s+/g, '\u00A0')}</p>}
      <p>{ls("Email", <span>E&#8209;Mail</span>, "Емейл")}:&nbsp;{email}</p>
      {isDeleted && <p>{ls(
        <span style={{ color: "#ffadad" }}>Account is blocked</span>,
        <span style={{ color: "#ffadad" }}>Konto ist gesperrt</span>,
        <span style={{ color: "#ffadad" }}>Аккаунт заблокирован</span>)}</p>}
      {isAdmin && !isSuperAdmin && (
        <p>{ls("Status", "Status", "Статус")}:&nbsp;{ls("admin", "Administrator", "админ")}</p>
      )}
      {isSuperAdmin && (
        <p>{ls("Status", "Status", "Статус")}:&nbsp;{ls("super\u2011admin", "Super\u2011Administrator", "супер\u2011админ")}</p>
      )}
      <button className="button-login" onClick={handleLogout}>
        {ls("Logout", "Abmelden", "Выйти")}
      </button>
      {message && <p>{message}</p>}
    </div>
  )
}

const Login = () => {
  const [message, setMessage] = useState('')
  const [password, setPassword] = useState('')
  const [login, setLogin] = useState('')
  const { login: loginFunction } = useAuth()

  const handleLogin = async () => {
    try {
      const response = await fetch(`${config.apiFolder}login.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ login, password }),
      })

      const responseText = await response.text()

      let responseData
      try {
        responseData = JSON.parse(responseText)
      } catch (jsonError) {
        throw new Error('Invalid JSON response from server')
      }

      if (!response.ok) {
        const serverMessage = responseData.message || `HTTP error! Status: ${response.status}`
        throw new Error(serverMessage)
      }

      if (responseData.jwt) {
        loginFunction(responseData.jwt)
      } else {
        throw new Error('Login failed: JWT token not provided')
      }
    } catch (error) {
      setMessage(`Login error: ${error.message}`)
    }
  }

  return (
    <div className="login-signup-forms">

      <div className="login-form">
        <h2>Login</h2>
        <input
          type="text"
          placeholder="Username or Email"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin}>Login</button>
      </div>

      {message && <p>{message}</p>}
    </div>
  )
}
