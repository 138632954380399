import React from 'react'
import packageJson from '../package.json'
import lastModified from '__fileLastModified__'
import './Footer.less'

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2)
    month = '0' + month
  if (day.length < 2)
    day = '0' + day

  return [day, month, year].join('.')
}

function Footer() {
  let date = new Date(lastModified)
  return (
    <footer>
      <div className='footer-text'>
        &copy; {date.getFullYear()} created by MozgOFF v{packageJson.version} ({formatDate(lastModified)})
      </div>
    </footer>
  )
}

export default Footer